import React, { useState } from "react";
import { Student } from "./StudentInterface";
import TableRow from "./TableRow";
import {TrombiService} from "../../../services/TrombiService";
import {useAuth} from "react-oidc-context";
import {Group} from "../../../interfaces/AuthentificationInterface";

export interface TableViewProps {
    students: Student[];
  searchTerm: string;
  columns: { key: string; label: string; visible: boolean }[];
  sortCriterion: keyof Student | null;
  filters:  Filters;
  selectedPromotion: string;
}
export interface Filters {
    selectedGroups: string[];
    selectedGroupsName: string[];
    selectedPAEs: string[];
    selectedCECs: string[];
    selectedInternationals: string[];
}

export default function TableView({ students, searchTerm, columns, sortCriterion, filters, selectedPromotion}: TableViewProps) {
    const [currentPage, setCurrentPage] = useState(1);
    const studentsPerPage = 10;
    const auth  = useAuth();
    const role = auth.user?.profile.group as Array<Group>;

    // Filtres
    const filteredStudents = students.filter((student) => {
        const searchFilter =
        student.login.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.email.toLowerCase().includes(searchTerm.toLowerCase());

        const groupFilter =
        filters.selectedGroups.length === 0 ||
        filters.selectedGroups.includes(student.group.toString());
        const groupNameFilter = 
        filters.selectedGroupsName.length === 0 ||
        filters.selectedGroupsName.includes(student.groupName);
        const paeFilter =
        filters.selectedPAEs.length === 0 ||
        filters.selectedPAEs.includes(student.groupPAE);
        const cecFilter =
        filters.selectedCECs.length === 0 ||
        filters.selectedCECs.includes(student.groupCEC);
        const internationalFilter =
        filters.selectedInternationals.length === 0 ||
        filters.selectedInternationals.includes(student.abroadSemester);

        return (
            searchFilter &&
        groupNameFilter &&
        groupFilter &&
        paeFilter &&
        cecFilter &&
        internationalFilter
        );
    });

    if (sortCriterion) {
        filteredStudents.sort((a, b) => {
            const aValue = a[sortCriterion];
            const bValue = b[sortCriterion];

            if (aValue == null && bValue == null) return 0;
            if (aValue == null) return 1;
            if (bValue == null) return -1;

            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
        });
    }

    // Pagination
    const indexOfLastStudent = currentPage * studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
    const currentStudents = filteredStudents.slice(
        indexOfFirstStudent,
        indexOfLastStudent
    );

    const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const optionColumns = columns.reduce((acc, col) => {
        acc[col.key] = col.visible;
        return acc;
    }, {} as any);

    const exportTrombi = async () => {
        const filteredData = TrombiService.createFilterPayload(filters, optionColumns);
        const promoExport = role.includes(Group.Representative) ? auth.user?.profile["promotion"] as string : selectedPromotion;
        await TrombiService.getTrombi(auth, promoExport , filteredData);
    };

    return (
        <div className="d-flex flex-column">
            {/* Table */}
            <div className="container mt-4 flex-grow-1">
                <table className="table table-striped table-hover">
                    <thead className="table-light">
                        <tr>
                            {optionColumns.lastName && <th style={{ width: "20%" }}>Nom</th>}
                            {optionColumns.firstName && <th style={{ width: "20%" }}>Prénom</th>}
                            {optionColumns.phone && (
                                <th style={{ width: "13.33%" }} className="text-center">
                    Téléphone
                                </th>
                            )}
                            {optionColumns.email && (
                                <th style={{ width: "13.33%" }} className="text-center">
                    Email
                                </th>
                            )}
                            {optionColumns.group && (
                                <th style={{ width: "18.33%" }} className="text-center">
                    Equipe
                                </th>
                            )}
                            {optionColumns.groupName && (
                                <th style={{ width: "18.33%" }} className="text-center">
                    Nom
                                </th>
                            )}
                            {optionColumns.pae && (
                                <th style={{ width: "8.33%" }} className="text-center">
                    PAE
                                </th>
                            )}
                            {optionColumns.cec && (
                                <th style={{ width: "13.33%" }} className="text-center">
                    CEC
                                </th>
                            )}
                            {optionColumns.international && (
                                <th style={{ width: "13.33%" }} className="text-center">
                    International
                                </th>
                            )}
                            {optionColumns.linkedin && (
                                <th style={{ width: "13.33%" }} className="text-center">
                    LinkedIn
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {currentStudents.map((student, index) => (
                            <TableRow
                                key={index}
                                student={student}
                                optionColumns={optionColumns}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div
                className={`d-flex mt-4 ${
                    role.includes(Group.MajorLeader) ||
            role.includes(Group.Teacher) ||
            role.includes(Group.Representative)
                        ? "justify-content-between"
                        : "justify-content-center"
                }`}
            >
                {(role.includes(Group.MajorLeader) ||
            role.includes(Group.Teacher) ||
            role.includes(Group.Representative)) && (
                    <div className="d-flex justify-content-center w-25">
                        <button className="h-50 btn btn-primary" onClick={exportTrombi}>
                Exporter le trombinoscope
                        </button>
                    </div>
                )}
                {/* Pagination */}
                <nav className="d-flex justify-content-center mt-auto mb-4 w-50">
                    <ul className="pagination">
                        <li
                            className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                        >
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                  &laquo;
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li
                                key={i + 1}
                                className={`page-item ${
                                    currentPage === i + 1 ? "active" : ""
                                }`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                        <li
                            className={`page-item ${
                                currentPage === totalPages ? "disabled" : ""
                            }`}
                        >
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                  &raquo;
                            </button>
                        </li>
                    </ul>
                </nav>
                {(role.includes(Group.MajorLeader) ||
            role.includes(Group.Teacher) ||
            role.includes(Group.Representative)) && (
                    <div className="w-25"></div>
                )}
            </div>
        </div>
    );
}
